import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { spacing } from '../../style/variables';
import { breakpoint } from '../../style/utils';

const ContainerElement = styled.div`
  margin: 0 auto;
  max-width: 920px;
  padding-left: ${spacing.pagePadding}px;
  padding-right: ${spacing.pagePadding}px;
  overflow: hidden;

  @media ${breakpoint('lg')} {
    max-width: 780px;
  }
  @media ${breakpoint('md')} {
    max-width: 600px;
  }
  @media ${breakpoint('sm')} {
    max-width: 520px;
  }
`;

const Container: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (
  props
) => <ContainerElement {...props} />;

export default Container;
