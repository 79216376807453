import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
  ArticleHighlightLink,
  ArticleHighlightImageLink,
  ArticleHighlightImage,
  ArticleHighlightTitleRow,
  ArticleHighlightTag,
  ArticleHighlightTags,
  ArticleHighlightDescription,
  ArticleHighlightFooter,
  ArticleHighlightDate,
  ArticleHighlightDetails,
  ArticleHighlightTitleText,
  ArticleHighlightRoot,
  HighlightAuthorIdentity,
} from './ArticleHighlight.style';
import { ArticleHighlightFragment } from '../../../generated/graphql-types';

interface ArticleHighlightProps {
  article: ArticleHighlightFragment;
}

const ArticleHighlight: FunctionComponent<ArticleHighlightProps> = ({
  article: { heroImage, slug, title, description, tags, author, publishDate },
}) => (
  <ArticleHighlightRoot>
    {heroImage?.gatsbyImageData && (
      <ArticleHighlightImageLink to={`/post/${slug}`}>
        <ArticleHighlightImage image={getImage(heroImage as any) as any} alt={title ?? 'Article'} />
      </ArticleHighlightImageLink>
    )}
    <ArticleHighlightDetails>
      <ArticleHighlightLink to={`/post/${slug}`}>
        <ArticleHighlightTitleRow>
          <ArticleHighlightTitleText>{title}</ArticleHighlightTitleText>
          {tags && tags.length > 0 && (
            <ArticleHighlightTags>
              {tags.map((tag) => (
                <ArticleHighlightTag key={tag}>{tag}</ArticleHighlightTag>
              ))}
            </ArticleHighlightTags>
          )}
        </ArticleHighlightTitleRow>
      </ArticleHighlightLink>
      {description?.childMarkdownRemark?.html && (
        <ArticleHighlightDescription
          dangerouslySetInnerHTML={{
            __html: description?.childMarkdownRemark.html,
          }}
        />
      )}
      <ArticleHighlightFooter>
        {author && <HighlightAuthorIdentity author={author} />}
        <ArticleHighlightDate>{publishDate}</ArticleHighlightDate>
      </ArticleHighlightFooter>
    </ArticleHighlightDetails>
  </ArticleHighlightRoot>
);

export default ArticleHighlight;

export const articleHighlightFragment = graphql`
  fragment ArticleHighlight on ContentfulArticle {
    title
    slug
    publishDate(formatString: "MMM Do YYYY")
    tags
    heroImage {
      gatsbyImageData(height: 450, placeholder: BLURRED)
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    author {
      name
      slug
      image {
        gatsbyImageData(width: 100, placeholder: BLURRED)
      }
    }
  }
`;
