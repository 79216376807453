import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby';
import { breakpoint } from '../../style/utils';
import { spacing, colours } from '../../style/variables';
import AuthorIdentity, { AuthorIdentityImage } from '../AuthorIdentity';

const highlightGutter = {
  desktop: 32,
  mobile: 24,
};

export const ArticleHighlightImage = styled(GatsbyImage)`
  max-width: 100%;
  height: 240px;
  border-radius: 12px;
  margin: ${highlightGutter.desktop / 2}px;

  @media ${breakpoint('md')} {
    height: 200px;
  }
  @media ${breakpoint({ min: 'xs', max: 'sm' })} {
    height: 180px;
    margin: ${highlightGutter.mobile / 2}px;
  }
  @media ${breakpoint('xxs')} {
    height: 200px;
    max-width: none;
    border-radius: 0;
    margin: 0 -${spacing.pagePadding}px 16px -${spacing.pagePadding}px;
  }
`;

export const ArticleHighlightImageLink = styled(Link)`
  flex: 1 0 360px;

  ${ArticleHighlightImage} {
    transition: transform 0.2s ease-in-out;
  }

  &:focus,
  &:hover,
  &:active {
    ${ArticleHighlightImage} {
      transform: scale(0.98);
    }
  }
`;

export const ArticleHighlightTitleRow = styled.h3`
  line-height: 40px;
  margin: 0 0 16px 0;
`;

export const ArticleHighlightTitleText = styled.span`
  margin-right: 16px;
  line-height: 40px;
`;

export const ArticleHighlightTags = styled.span`
  margin: 0 -3px;
  display: inline-flex;
  height: 40px;
  vertical-align: top;
  align-items: center;
`;

export const ArticleHighlightTag = styled.span`
  display: block;
  margin: 3px;
  background-color: #b5dbf6;
  font-weight: bold;
  font-size: 18px;
  border-radius: 24px;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  font-style: normal;
`;

export const ArticleHighlightDescription = styled.div`
  font-size: 20px;
  margin: 0 0 16px 0;

  > p:first-of-type {
    margin-top: 0;
  }
  > p:last-of-type {
    margin-bottom: 0;
  }
`;

export const ArticleHighlightFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

export const ArticleHighlightDate = styled.span`
  opacity: 0.6;
  font-style: italic;
`;

export const ArticleHighlightRoot = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: -${highlightGutter.desktop / 2}px;

  @media ${breakpoint({ max: 'sm' })} {
    margin: -${highlightGutter.mobile / 2}px;
  }
  @media ${breakpoint('xxs')} {
    margin: 0;
  }
`;

export const HighlightAuthorIdentity = styled(AuthorIdentity)`
  padding-right: 20px;

  ${AuthorIdentityImage} {
    width: 36px;
    height: 36px;
  }
`;

export const ArticleHighlightDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${highlightGutter.desktop / 2}px;
  flex: 1 0 400px;
  max-width: 100%;

  @media ${breakpoint('xxs')} {
    margin: 0;
    flex: 0 0 100%;
  }
`;

export const ArticleHighlightLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  ${ArticleHighlightTitleText} {
    transition: color 0.2s ease-in-out;
  }

  &:focus,
  &:hover,
  &:active {
    ${ArticleHighlightTitleText} {
      color: ${colours.primary};
    }
  }
`;
